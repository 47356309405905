import Image from "next/image";

const BrowserNotSupported = () => {
	return (
		<div className="browser-not-supported__container">
			<div className="browser-not-supported__container-margin">
				<h1 className="browser-not-supported__title">Przykro nam :(</h1>
				<h2 className="browser-not-supported__subtitle">
					Internet Explorer stwarza problemy techniczne.
					<br />
					Otwórz Przyjazne Deklaracje w jednej z wymienionych przeglądarek:
				</h2>
				<div className="browser-not-supported__browsers-list">
					<div className="browser-not-supported__browsers-list-item">
						<Image unoptimized 
							src="https://przyjazne-deklaracje.pl/resources/images/icons/general/browsers/chrome-logo.svg"
							alt=""
							width={64}
							height={64}
							style={{
								maxWidth: "100%",
								height: "auto",
							}}
						/>
						<span className="browser-not-supported__browsers-list-item-name">Google Chrome</span>
					</div>
					<div className="browser-not-supported__browsers-list-item">
						<Image unoptimized 
							src="https://przyjazne-deklaracje.pl/resources/images/icons/general/browsers/mozilla-logo.svg"
							alt=""
							width={64}
							height={64}
							style={{
								maxWidth: "100%",
								height: "auto",
							}}
						/>
						<span className="browser-not-supported__browsers-list-item-name">Mozilla Firefox</span>
					</div>
					<div className="browser-not-supported__browsers-list-item">
						<Image unoptimized 
							src="https://przyjazne-deklaracje.pl/resources/images/icons/general/browsers/safari-logo.png"
							alt=""
							width={64}
							height={64}
							style={{
								maxWidth: "100%",
								height: "auto",
							}}
						/>
						<span className="browser-not-supported__browsers-list-item-name">Safari</span>
					</div>
					<div className="browser-not-supported__browsers-list-item">
						<Image unoptimized 
							src="https://przyjazne-deklaracje.pl/resources/images/icons/general/browsers/edge-logo.png"
							alt=""
							width={64}
							height={64}
							style={{
								maxWidth: "100%",
								height: "auto",
							}}
						/>
						<span className="browser-not-supported__browsers-list-item-name">Microsoft Edge</span>
					</div>
					<div className="browser-not-supported__browsers-list-item">
						<Image unoptimized 
							src="https://przyjazne-deklaracje.pl/resources/images/icons/general/browsers/opera-logo.png"
							alt=""
							width={64}
							height={64}
							style={{
								maxWidth: "100%",
								height: "auto",
							}}
						/>
						<span className="browser-not-supported__browsers-list-item-name">Opera</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BrowserNotSupported;
